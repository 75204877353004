import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'datetimeFormat'
})
export class DatetimeFormat extends DatePipe implements PipeTransform {
  /*
  transform(
    value: string | Number | Date = '',
    format: string = 'mediumDate',
    timezone: string = 'America/New_York',
    locale: string = ''
  ): string {
    const timezoneOffset = dayjs(value).tz(timezone).format('Z');
    return '';
    //return super.transform(value, format, timezoneOffset);
  }
  */
}
