import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError, shareReplay } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpBackend } from '@angular/common/http';
import { ApiService, HttpErrorCallback } from '../../../services/api.service';
import { ConfigurationService } from '../../../services/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

	constructor(
		private httpClient: HttpClient,
		private apiService: ApiService,
		private configurationService: ConfigurationService) { }

 	getChart(webhookID: string, version: string, callback: HttpErrorCallback): Observable<any> {
    return this.httpClient
	    .get<any>(this.configurationService.chartApiUrl + '/charts/' + webhookID + '/' + version, this.apiService.getHttpOptions())
	    .pipe(shareReplay(1),
	      catchError(callback.handleApiError));
	}

  deleteImportDataset(webhookID: string, callback: HttpErrorCallback): Observable<any> {
    return this.httpClient
      .delete<any>(this.configurationService.chartApiUrl + '/charts/' + webhookID + '/import', this.apiService.getHttpOptions())
      .pipe(map(data => {
        return data;
    }), shareReplay(1),
    catchError(callback.handleApiError));
  }

  importDataset(title: string, url: string, s3PutUrl: string, s3GetUrl: string, callback: HttpErrorCallback): Observable<any> {
    let req = {url:url, s3PutUrl: s3PutUrl, s3GetUrl:s3GetUrl, title:title };
    return this.httpClient
      .post<any>(this.configurationService.chartApiUrl + '/charts/import', req, this.apiService.getHttpOptions())
      .pipe(map(data => {
        return data;
    }), shareReplay(1),
    catchError(callback.handleApiError));
  }

  importDatasetWithSheetName(s3GetUrl: string, title: string, sourceUrl: string, sheetName: string, callback: HttpErrorCallback): Observable<any> {
    let req = {sheetName:sheetName, s3GetUrl: s3GetUrl, title: title, sourceUrl: sourceUrl };
    return this.httpClient
      .post<any>(this.configurationService.chartApiUrl + '/charts/import', req, this.apiService.getHttpOptions())
      .pipe(map(data => {
        return data;
    }), shareReplay(1),
    catchError(callback.handleApiError));
  }

  refreshDataset(webhookId: string, url:string, sheetName: string, callback: HttpErrorCallback): Observable<any> {
    let req = {sheetName:sheetName, url: url };
    return this.httpClient
      .post<any>(this.configurationService.chartApiUrl + '/charts/' + webhookId + '/import', req, this.apiService.getHttpOptions())
      .pipe(map(data => {
        return data;
    }), shareReplay(1),
    catchError(callback.handleApiError));
  }

	postChart(webhookID: string, version: string, saveResponse: boolean, req: ChartRequest, callback: HttpErrorCallback): Observable<any> {
  	return this.httpClient
    	.post<any>(this.configurationService.chartApiUrl + '/charts/' + webhookID + '/' + version + '?save=true', req, this.apiService.getHttpOptions())
    	.pipe(map(data => {

    		req.props = data.props;
    		return {request: req, response: data};

  	}), shareReplay(1),
    catchError(callback.handleApiError));
	}

  createImage(type: string, width: number, height: number, chart: any, callback: HttpErrorCallback): Observable<any> {

    let req = {chart:chart,type:type};
    var options : any = this.apiService.getHttpOptions();

    if (type == "svg") {
      options = { headers: options.headers, responseType: 'blob' }; 
    } else {
      options = { headers: options.headers, responseType: 'string' };
    }

    return this.httpClient
      .post<any>(this.configurationService.chartApiUrl + '/charts/image', req, options)
      .pipe(shareReplay(1),
    catchError(callback.handleApiError));
  }

  publishImage(webhookID: string, type: string, chart: any, callback: HttpErrorCallback): Observable<any> {

    let req = {chart:chart,type:type};
    let options : any = this.apiService.getHttpOptions();

    return this.httpClient
      .post<any>(this.configurationService.chartApiUrl + '/charts/' + webhookID + '/image', req, options)
      .pipe(shareReplay(1),
    catchError(callback.handleApiError));
  }

  updateRow(webhookID: string, version: string, id: string, key: string, val: any, callback: HttpErrorCallback) {
    var body = {};
    body[key] = val;

    return this.httpClient
      .patch<any>(this.configurationService.chartApiUrl + '/charts/' + webhookID + "/" + version + "/" + id, body, this.apiService.getHttpOptions())
      .pipe(map(data => {
        return data;
      }), shareReplay(1),
      catchError(callback.handleApiError));
  }

  deleteRow(webhookID: string, id: string, callback: HttpErrorCallback) {
    const body = {};
    return this.httpClient
      .delete<any>(this.configurationService.chartApiUrl + '/charts/' + webhookID + "/" + id, this.apiService.getHttpOptions())
      .pipe(map(data => {
        return data;
      }), shareReplay(1),
      catchError(callback.handleApiError));
  }
}

export class ChartRequest {
	public ts = CHART_TIME_SERIES.POINT;
	public start = null;
	public end = null;
	public limit = null;
	public type = "line";
	public sorted = null;
  columns = null;
	title = null;
  subtitle = null;
  datetitle = "ENABLED";
	xlabel = null;
	ylabel = null;
	props = null;
	result = null;
}

export const CHART_SORT = {
  ASC : "asc",
  DESC : "desc"
};

export const CHART_TIME_SERIES = {
  POINT : "point",
  HOUR : "hour",
  DAY : "day",
  MONTH : "month",
  WEEK : "week",
  YEAR : "year"
};

export const CHART_TYPES = {
  BAR : "bar",
  BAR_HORIZONTAL : "horizontalBar",
  PIE : "pie",
  LINE : "line"
};
